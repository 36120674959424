import React, { Fragment, useState, useRef, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Sidebar } from "../../partials/Sidebar";
import { Navbar } from "../../partials/Navbar";
import { useParams, useNavigate } from "react-router-dom";
import { ExclamationIcon } from "@heroicons/react/outline";
import * as gameService from "../../services/game.service";
import LoadingIcon from "../../components/LoadingIcon";
import LoadingButtonIcon from "../../components/LoadingButtonIcon";
import GameNameEdit from "../../components/game/GameNameEdit";
import GameDescriptionEdit from "../../components/game/GameDescriptionEdit";
import GameUrlEdit from "../../components/game/GameUrlEdit";
import GameImageEdit from "../../components/game/GameImageEdit";
import GameThumbnailEdit from "../../components/game/GameThumbnailEdit";
import GameCategoryEdit from "../../components/game/GameCategoryEdit";

export default function GameView() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [game, setGame] = useState(null);
  const [gameNotFound, setGameNotFound] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteButtonLoading, setDeleteButtonLoading] = useState(false);
  const [disabledGame, setDisabledGame] = useState(null);
  const [submittingDisable, setSubmittingDisable] = useState(false);

  const cancelDeleteButtonRef = useRef(null);

  let navigate = useNavigate();

  const { id } = useParams();

  const deleteGame = () => {
    setDeleteButtonLoading(true);
    gameService.deleteGame(id).then((response) => {
      if (response.status === 200) {
        navigate("/games");
      } else {
        // TODO: handle error
      }
    });
  };

  const disableGame = () => {
    setSubmittingDisable(true);
    gameService.disable(id).then((response) => {
      if (response.status === 200) {
        setSubmittingDisable(false);
        setDisabledGame(response.data.data.published ? false : true);
      }
    });
  };

  useEffect(() => {
    if (id) {
      gameService.get(id).then((response) => {
        if (response.status === 200) {
          setGame(response.data.data);
          setDisabledGame(response.data.data.published ? false : true);
          setLoading(false);
        } else if (response.status === 404) {
          setGameNotFound(true);
          setLoading(false);
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Transition.Root show={deleteModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelDeleteButtonRef}
          onClose={setDeleteModalOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        Delete Game
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Are you sure you want to delete this game? This is a
                          permanent action.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={deleteGame}
                    disabled={deleteButtonLoading}
                  >
                    {deleteButtonLoading ? <LoadingButtonIcon /> : "Delete"}
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setDeleteModalOpen(false)}
                    ref={cancelDeleteButtonRef}
                    disabled={deleteButtonLoading}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <div>
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <Navbar sidebarOpen={sidebarOpen}>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <h1 className="text-2xl font-semibold text-gray-900">Games</h1>
          </div>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            {/* Replace with your content */}
            <div className="py-4">
              <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                {loading ? (
                  <LoadingIcon />
                ) : gameNotFound ? (
                  <div className="text-center p-8">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="mx-auto h-12 w-12 text-gray-400"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M10 21h7a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v11m0 5l4.879-4.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242z"
                      />
                    </svg>
                    <h3 className="mt-2 text-sm font-medium text-gray-900">
                      No Game Found
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      This game is ethier not exist or deleted.
                    </p>
                  </div>
                ) : (
                  <>
                    <div className="px-4 py-5 sm:px-6 flex flex-row justify-between items-center">
                      <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                          Game Information
                        </h3>
                        <p className="mt-1 max-w-2xl text-sm text-gray-500">
                          Technical informations and description.
                        </p>
                      </div>
                      <div className="flex items-center">
                        <button
                          type="button"
                          onClick={() => disableGame()}
                          className="mr-3 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          {submittingDisable ? (
                            <LoadingButtonIcon color="GRAY" />
                          ) : disabledGame ? (
                            "Enable Game"
                          ) : (
                            "Disable Game"
                          )}
                        </button>
                        <button
                          type="button"
                          onClick={() => setDeleteModalOpen(true)}
                          className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-red-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Delete Game
                        </button>
                      </div>
                    </div>
                    <div className="border-t border-gray-200">
                      <dl>
                        <GameNameEdit game={game} />
                        <GameDescriptionEdit game={game} />
                        <GameCategoryEdit game={game} />
                        <GameImageEdit game={game} />
                        <GameThumbnailEdit game={game} />
                        <GameUrlEdit game={game} />
                        {/* <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-gray-500">
                            Game URL
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            <ul className="border border-gray-200 rounded-md divide-y divide-gray-200 bg-white">
                              <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                                <div className="w-0 flex-1 flex items-center">
                                  <PaperClipIcon
                                    className="flex-shrink-0 h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                  <span className="ml-2 flex-1 w-0 truncate">
                                    {game.url}
                                  </span>
                                </div>
                                <div className="ml-4 flex-shrink-0">
                                  <Link
                                    to="/"
                                    className="font-medium text-indigo-600 hover:text-indigo-500"
                                  >
                                    Change
                                  </Link>
                                </div>
                              </li>
                            </ul>
                          </dd>
                        </div> */}
                      </dl>
                    </div>{" "}
                  </>
                )}
                {/* End content */}
              </div>
            </div>
            {/* /End replace */}
          </div>
        </Navbar>
      </div>
    </>
  );
}
