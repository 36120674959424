import * as authService from "../../services/auth.service";

const loginAction = (payload) => ({ type: "LOGIN", payload });
const invalidCredentialsAction = () => ({ type: "INVALID_CREDENTIALS" });

const logoutAction = () => ({ type: "LOGOUT" });

export const login = (phoneNumber, password) => (dispatch) => {
  authService
    .login(phoneNumber, password)
    .then((response) => {
      localStorage.setItem("accessToken", response.data.data.accessToken);
      dispatch(loginAction(response.data.data));
    })
    .catch((error) => {
      if (error.response.status === 404) {
        dispatch(invalidCredentialsAction());
      }
    });
};

export const logout = () => (dispatch) => {
  localStorage.clear();
  dispatch(logoutAction());
};

// export const register = (userInfo) => (dispatch) => {
//   fetch(`http://localhost:4000/users`, {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//       Accept: "application/json",
//     },
//     body: JSON.stringify(userInfo),
//   })
//     .then((res) => res.json())
//     .then((data) => {
//       // data sent back will in the format of
//       // {
//       //     user: {},
//       //.    token: "aaaaa.bbbbb.bbbbb"
//       // }
//       localStorage.setItem("token", data.token);
//       dispatch(loginAction(data.user));
//     });
// };

export const clearState = () => ({ type: "CLEAR_STATE" });
