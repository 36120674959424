import React from "react";

export default function PreviewGameUpload({ file, classNames, error }) {
  const [preview, setPreview] = React.useState(null);

  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    setPreview(reader.result);
  };

  return (
    <img
      src={preview}
      alt="Preview"
      className={
        error
          ? "outline-none ring-2 ring-offset-2 ring-red-500 " + classNames
          : classNames
      }
    />
  );
}
