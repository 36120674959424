export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const toAbsoluteUrl = (pathname) => process.env.PUBLIC_URL + pathname;

export const SUPPORTED_IMAGE_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/gif",
  "image/png",
];
export const MAX_IMAGE_FILE_SIZE = 2 * 1024 * 1024;

export const getDaysBetween = (startDate, endDate) => {
  const dateArray = [];
  let currentDate = new Date(startDate);

  while (currentDate <= new Date(endDate)) {
    dateArray.push(formatDateToString(currentDate));
    currentDate.setUTCDate(currentDate.getUTCDate() + 1);
  }

  return dateArray;
};

export const formatDateToString = (d) => {
  var month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};
