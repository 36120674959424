const defaultState = {
  authenticated: false,
  user: null,
  error: null,
};

const authReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        authenticated: true,
        user: { ...action.payload },
        error: null,
      };

    case "LOGOUT":
      return {
        authenticated: false,
        user: null,
        error: null,
      };

    case "INVALID_CREDENTIALS":
      return {
        authenticated: false,
        user: null,
        error: "INVALID_CREDENTIALS",
      };

    case "CLEAR_STATE":
      return {
        authenticated: false,
        user: null,
        error: null,
      };
    default:
      return state;
  }
};

export default authReducer;
