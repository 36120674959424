import React from "react";

export default function UserListItem({ user, evenIndex }) {
  return (
    <tr className={evenIndex ? "bg-white" : "bg-gray-50"}>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="flex items-center">
          <div className="flex-shrink-0 h-10 w-10">
            {user.avatar ? (
              <img
                className="h-10 w-10 rounded-full"
                src={user.avatar}
                alt=""
              />
            ) : (
              <span className="inline-block h-10 w-10 rounded-full overflow-hidden bg-gray-100">
                <svg
                  className="h-full w-full text-gray-300"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                </svg>
              </span>
            )}
          </div>
          <div className="ml-4">
            <div className="text-sm font-medium text-gray-900">
              {user.firstName + " " + user.lastName}
            </div>
            <div className="text-sm text-gray-500">+249 {user.phoneNumber}</div>
          </div>
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="text-sm text-gray-900">{user.subscriptionStatus}</div>
        <div className="text-sm text-gray-500">
          Until: {user.subscriptionDate}
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        {user.active ? (
          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
            Active
          </span>
        ) : (
          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-600">
            Deactivated
          </span>
        )}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
        <button type="button" className="text-indigo-600 hover:text-indigo-900">
          Deactivate
        </button>
      </td>
    </tr>
  );
}
