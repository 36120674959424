import React, { useState, useEffect } from "react";
import * as reportService from "../../../services/report.service";
import "react-datepicker/dist/react-datepicker.css";
import Chart from "react-apexcharts";
import { getDaysBetween, formatDateToString } from "./../../../utils/common";
import LoadingIcon from "../../../components/LoadingIcon";

export default function Year() {
  const now = new Date();
  const [startDate, setStartDate] = useState(
    new Date(now.getFullYear(), now.getMonth() - 12, now.getDate())
  );
  const [endDate, setEndDate] = useState(now);
  const [chartDays, setChartDays] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [noData, setNoDate] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [loadingDateSubscriptions, setLoadingDateSubscriptions] =
    useState(false);
  const [dateSubscriptions, setDateSubscriptions] = useState(null);

  const apiUrl = process.env.REACT_APP_API_URL;

  const series = [
    {
      name: "Zain subscribers",
      data: chartData,
    },
  ];

  const options = {
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      events: {
        click: function (event, chartContext, config) {
          getDateSubscriptions(chartDays[config.dataPointIndex]);
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },
    title: {
      text: "Users subscriptions by date range",
      align: "left",
      style: {
        fontFamily: "Segoe UI",
        fontWeight: 600,
      },
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    xaxis: {
      type: "datetime",
      categories: chartDays,
    },
    yaxis: {
      tickAmount: 6,
    },
  };

  const getDateSubscriptions = (date) => {
    setSelectedDate(date);
    setLoadingDateSubscriptions(true);
    reportService
      .subscriptionsByDate(date)
      .then((response) => {
        setLoadingDateSubscriptions(false);
        if (response.status === 200) {
          setDateSubscriptions(response.data.data);
        }
      })
      .catch((error) => console.log("getDateSubscriptions error: ", error));
  };

  const structureData = (data) => {
    let daysBetween = getDaysBetween(
      formatDateToString(startDate),
      formatDateToString(endDate)
    );
    let newChartData = new Array(daysBetween.length).fill(0);
    daysBetween.forEach((day, dayIdx) => {
      data.forEach((dataRow) => {
        if (dataRow.day === day) newChartData[dayIdx] = dataRow.count;
      });
    });

    return { daysBetween, newChartData };
  };

  const createReport = () => {
    if (startDate && endDate) {
      setSelectedDate(null);
      setLoadingDateSubscriptions(false);
      setDateSubscriptions(null);
      reportService
        .subscriptionsByDateRange(
          formatDateToString(startDate),
          formatDateToString(endDate)
        )
        .then((repsonse) => {
          if (repsonse.data.data && repsonse.data.data.length > 0) {
            setNoDate(false);
            let { daysBetween, newChartData } = structureData(
              repsonse.data.data
            );
            setChartData(newChartData);
            setChartDays(daysBetween);
          } else {
            setNoDate(true);
          }
        })
        .catch((error) => console.log("error: ", error));
    }
  };

  useEffect(() => {
    createReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="mt-4">
        {noData ? (
          <div className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="mx-auto h-12 w-12 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z"
              />
            </svg>
            <span className="mt-2 block text-sm font-medium text-gray-900">
              No data for this year.
            </span>
          </div>
        ) : (
          chartData &&
          chartData.length > 0 &&
          chartDays &&
          chartDays.length > 0 && (
            <Chart options={options} series={series} type="line" height={350} />
          )
        )}
      </div>
      {selectedDate && (
        <div className="mt-4">
          {loadingDateSubscriptions ? (
            <LoadingIcon />
          ) : (
            dateSubscriptions && (
              <div className="mt-8 flex flex-col">
                {dateSubscriptions.length > 0 ? (
                  <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                      <div className="sm:flex sm:items-center mb-4 mx-2">
                        <div className="sm:flex-auto">
                          <h1 className="text-xl font-semibold text-gray-900">
                            Subscriptions
                          </h1>
                          <p className="text-sm text-gray-700">
                            Newly added subscriptions on date: {selectedDate}
                          </p>
                        </div>
                      </div>
                      <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                        {/* <table className="min-w-full divide-y divide-gray-300">
                          <thead className="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                              >
                                Name
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Title
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Email
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Role
                              </th>
                              <th
                                scope="col"
                                className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                              >
                                <span className="sr-only">Edit</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody className="bg-white">
                            {dateSubscriptions.map(
                              (dateSubscription, dateSubscriptionIdx) => (
                                <tr
                                  key={dateSubscriptionIdx.toString()}
                                  className={
                                    dateSubscriptionIdx % 2 === 0
                                      ? undefined
                                      : "bg-gray-50"
                                  }
                                >
                                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                    {dateSubscription.phoneNumber}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {dateSubscription.interval}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {dateSubscription.from}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {dateSubscription.active}
                                  </td>
                                  <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                    <Link
                                      to="/"
                                      className="text-indigo-600 hover:text-indigo-900"
                                    >
                                      Edit
                                      <span className="sr-only">
                                        , {dateSubscription.createdAt}
                                      </span>
                                    </Link>
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table> */}
                        <table className="min-w-full divide-y divide-gray-300">
                          <thead className="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                              >
                                Name
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Subscription
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Status
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Operator
                              </th>
                              {/* <th
                                scope="col"
                                className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                              >
                                <span className="sr-only">Edit</span>
                              </th> */}
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200 bg-white">
                            {dateSubscriptions.map(
                              (dateSubscription, dateSubscriptionIdx) => (
                                <tr key={dateSubscriptionIdx.toString()}>
                                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                                    <div className="flex items-center">
                                      <div className="h-10 w-10 flex-shrink-0">
                                        {dateSubscription.user.avatar ? (
                                          <img
                                            className="h-10 w-10 rounded-full"
                                            src={
                                              apiUrl +
                                              "/resources/images/users/" +
                                              dateSubscription.user.avatar
                                            }
                                            alt=""
                                          />
                                        ) : (
                                          <span className="h-10 w-10 rounded-full overflow-hidden bg-gray-100">
                                            <svg
                                              className="h-full w-full text-gray-300 rounded-full"
                                              fill="currentColor"
                                              viewBox="0 0 24 24"
                                            >
                                              <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                            </svg>
                                          </span>
                                        )}
                                      </div>
                                      <div className="ml-4">
                                        <div className="font-medium text-gray-900">
                                          {dateSubscription.user.firstName +
                                            " " +
                                            dateSubscription.user.lastName}
                                        </div>
                                        <div className="text-gray-500">
                                          {dateSubscription.phoneNumber}
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    <div className="text-gray-900">
                                      {dateSubscription.price.name_en}
                                    </div>
                                    <div className="text-gray-500">
                                      {dateSubscription.amount}
                                    </div>
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {dateSubscription.active ? (
                                      <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                        Active
                                      </span>
                                    ) : (
                                      <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-600">
                                        Expired
                                      </span>
                                    )}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {dateSubscription.operator}
                                  </td>
                                  {/* <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                  <Link
                                    to="/"
                                    className="text-indigo-600 hover:text-indigo-900"
                                  >
                                    Edit
                                    <span className="sr-only">
                                      , {person.name}
                                    </span>
                                  </Link>
                                </td> */}
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-8 text-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="mx-auto h-12 w-12 text-gray-400"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                    <span className="mt-2 block text-sm font-medium text-gray-900">
                      No subscriptions for the selected date.
                    </span>
                  </div>
                )}
              </div>
            )
          )}
        </div>
      )}
    </>
  );
}
