import axios from "axios";
import { authHeader, authHeaderWithMultipart } from "../utils/authHeader";

const apiUrl = process.env.REACT_APP_API_URL;

export const list = () => {
  return axios.get(apiUrl + "/game/all/1/1000", {
    headers: authHeader(),
  });
};

export const get = (id) => {
  return axios.get(apiUrl + "/game/" + id, {
    headers: authHeader(),
  });
};

export const create = (game) => {
  let formData = new FormData();
  formData.append("name_en", game.name_en);
  formData.append("name_ar", game.name_ar);
  formData.append("description_ar", game.description_ar);
  formData.append("description_en", game.description_en);
  formData.append("categoryId", game.category_id);
  formData.append("url", game.url);
  formData.append("published", true);
  formData.append("image", game.image);
  formData.append("thumbnail", game.thumbnail);
  return axios.post(apiUrl + "/game", formData, {
    headers: authHeaderWithMultipart(),
  });
};

export const edit = (
  id,
  game,
  modifiers = { image: false, thumbnail: false }
) => {
  let formData = new FormData();
  formData.append("name_en", game.name_en);
  formData.append("name_ar", game.name_ar);
  formData.append("description_ar", game.description_ar);
  formData.append("description_en", game.description_en);
  formData.append("categoryId", game.categoryId);
  formData.append("url", game.url);
  formData.append("published", true);
  if (modifiers.image) {
    formData.append("image", game.image);
  }
  if (modifiers.thumbnail) {
    formData.append("thumbnail", game.thumbnail);
  }
  return axios.put(apiUrl + "/game/" + id, formData, {
    headers: authHeaderWithMultipart(),
  });
};

export const disable = (id) => {
  return axios.put(
    apiUrl + "/game/" + id + "/disable",
    {},
    {
      headers: authHeader(),
    }
  );
};

export const deleteGame = (id) => {
  return axios.delete(apiUrl + "/game/" + id, {
    headers: authHeader(),
  });
};
