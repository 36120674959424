import {
  FolderIcon,
  HomeIcon,
  PuzzleIcon,
  TagIcon,
  TrendingUpIcon,
  UsersIcon,
} from "@heroicons/react/outline";

export const navigationLinks = [
  { name: "Dashboard", href: "/", icon: HomeIcon },
  { name: "Games", href: "/games", icon: PuzzleIcon },
  { name: "Categories", href: "/categories", icon: FolderIcon },
  { name: "Pricings", href: "/pricings", icon: TagIcon },
  { name: "Users", href: "/users", icon: UsersIcon },
  {
    name: "Reports",
    href: "#",
    icon: TrendingUpIcon,
    children: [
      { name: "Subscriptions", href: "/report/subscriptions" },
      { name: "Revenue", href: "/" },
      { name: "Games", href: "/report/games" },
      { name: "Users", href: "/report/users" },
    ],
  },
];
