import React, { Fragment, useState, useRef, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Sidebar } from "../../partials/Sidebar";
import { Navbar } from "../../partials/Navbar";
import { Link } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  CurrencyDollarIcon,
  ExclamationIcon,
  PlusIcon,
  PlusSmIcon,
} from "@heroicons/react/outline";
import * as pricingService from "../../services/pricing.service";
import LoadingIcon from "../../components/LoadingIcon";
import LoadingButtonIcon from "../../components/LoadingButtonIcon";

export default function PricingList() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [submittingAdd, setSubmittingAdd] = useState(false);
  const [pricings, setPricings] = useState(null);
  const [loadingPricing, setLoadingPricings] = useState(true);

  const cancelAddButtonRef = useRef(null);

  const AddSchema = Yup.object().shape({
    name_en: Yup.string()
      .min(2, "Pricing english name must be 4 characters at minimum")
      .required("Category english name is required"),
    name_ar: Yup.string()
      .min(2, "Pricing arabic name must be 4 characters at minimum")
      .required("Category arabic name is required"),
    amount: Yup.string().required("Amount name is required"),
    interval: Yup.string().required("Interval is required"),
    period: Yup.number().required("Period is required"),
    description: Yup.string().required("Pricing description is required"),
  });

  const cancelDeleteButtonRef = useRef(null);

  const deleteGame = () => {};

  const handleCreate = (pricing) => {
    setSubmittingAdd(true);
    pricingService.create(pricing).then((response) => {
      setSubmittingAdd(false);
      if (response.status === 200) {
        let newPricings = pricings;
        newPricings.push(response.data.data);
        setPricings(newPricings);
        setAddModalOpen(false);
      } else {
        // TODO: handle error
      }
    });
  };

  // const pricings = [
  //   {
  //     id: 1,
  //     name: "Pro",
  //     active: true,
  //     amount: "59.99",
  //     closeDate: "2020-01-07",
  //     closeDateFull: "January 7, 2020",
  //   },
  //   {
  //     id: 2,
  //     name: "Enterprise",
  //     active: false,
  //     amount: "79.99",
  //     closeDate: "2020-01-07",
  //     closeDateFull: "January 7, 2020",
  //   },
  //   {
  //     id: 3,
  //     name: "Standard",
  //     active: true,
  //     amount: "39.99",
  //     closeDate: "2020-01-14",
  //     closeDateFull: "January 14, 2020",
  //   },
  // ];

  useEffect(() => {
    pricingService.list().then((response) => {
      if (response.status === 200) {
        setPricings(response.data.data.rows);
        setLoadingPricings(false);
      }
    });
  }, []);

  return (
    <>
      <Transition.Root show={addModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelAddButtonRef}
          onClose={setAddModalOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full">
                <Formik
                  initialValues={{
                    name_en: "",
                    name_ar: "",
                    amount: "",
                    period: "",
                    interval: "month",
                    description: "",
                  }}
                  validationSchema={AddSchema}
                  onSubmit={(values) => handleCreate(values)}
                >
                  {({ touched, errors }) => (
                    <Form>
                      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-start">
                          <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-indigo-50 sm:mx-0 sm:h-10 sm:w-10">
                            <CurrencyDollarIcon
                              className="h-6 w-6 text-indigo-500"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                            <Dialog.Title
                              as="h3"
                              className="text-lg leading-6 font-medium text-gray-900"
                            >
                              Add New Pricing
                            </Dialog.Title>
                            <div className="mt-3 w-full">
                              <div className="grid grid-cols-6 gap-6">
                                <div className="col-span-6 sm:col-span-3">
                                  <label
                                    htmlFor="name_en"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    English Name
                                  </label>
                                  <Field
                                    type="text"
                                    name="name_en"
                                    id="name_en"
                                    className={`mt-1 block w-full shadow-sm sm:text-sm rounded-md ${
                                      touched.name_en && errors.name_en
                                        ? "focus:ring-red-500 focus:border-red-500 border-red-300"
                                        : "focus:ring-indigo-500 focus:border-indigo-500 border-gray-300"
                                    }`}
                                  />
                                </div>

                                <div className="col-span-6 sm:col-span-3">
                                  <label
                                    htmlFor="name_ar"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    Arabic Name
                                  </label>
                                  <Field
                                    type="text"
                                    name="name_ar"
                                    id="name_ar"
                                    className={`mt-1 block w-full shadow-sm sm:text-sm rounded-md ${
                                      touched.name_ar && errors.name_ar
                                        ? "focus:ring-red-500 focus:border-red-500 border-red-300"
                                        : "focus:ring-indigo-500 focus:border-indigo-500 border-gray-300"
                                    }`}
                                  />
                                </div>

                                <div className="col-span-6 sm:col-span-3">
                                  <label
                                    htmlFor="amount"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    Amount
                                  </label>
                                  <Field
                                    type="text"
                                    name="amount"
                                    id="amount"
                                    className={`mt-1 block w-full shadow-sm sm:text-sm rounded-md ${
                                      touched.amount && errors.amount
                                        ? "focus:ring-red-500 focus:border-red-500 border-red-300"
                                        : "focus:ring-indigo-500 focus:border-indigo-500 border-gray-300"
                                    }`}
                                  />
                                </div>

                                <div className="col-span-6 sm:col-span-3">
                                  <label
                                    htmlFor="period"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    Period
                                  </label>
                                  <Field
                                    type="text"
                                    name="period"
                                    id="period"
                                    className={`mt-1 block w-full shadow-sm sm:text-sm rounded-md ${
                                      touched.period && errors.period
                                        ? "focus:ring-red-500 focus:border-red-500 border-red-300"
                                        : "focus:ring-indigo-500 focus:border-indigo-500 border-gray-300"
                                    }`}
                                  />
                                </div>

                                <div className="col-span-6 sm:col-span-3">
                                  <label
                                    htmlFor="interval"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    Interval
                                  </label>
                                  <Field
                                    as="select"
                                    id="interval"
                                    name="interval"
                                    className={`mt-1 block w-full py-2 px-3 border bg-white rounded-md shadow-sm sm:text-sm ${
                                      touched.interval && errors.interval
                                        ? "border-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500"
                                        : "border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                                    }`}
                                  >
                                    <option value="month">Month</option>
                                    <option value="year">Year</option>
                                  </Field>
                                </div>

                                <div className="col-span-6 sm:col-span-6">
                                  <label
                                    htmlFor="description"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    Description
                                  </label>
                                  <Field
                                    as="textarea"
                                    id="description"
                                    name="description"
                                    rows={4}
                                    className={`shadow-sm block w-full sm:text-sm rounded-md ${
                                      touched.description && errors.description
                                        ? "border-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500"
                                        : "border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                                    }`}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                        <button
                          type="submit"
                          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-75"
                          disabled={submittingAdd}
                        >
                          {submittingAdd ? <LoadingButtonIcon /> : "Add"}
                        </button>
                        <button
                          type="button"
                          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-75"
                          disabled={submittingAdd}
                          onClick={() => setAddModalOpen(false)}
                          ref={cancelAddButtonRef}
                        >
                          Cancel
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition.Root show={deleteModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelDeleteButtonRef}
          onClose={setDeleteModalOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        Delete Game
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Are you sure you want to delete this game? This is a
                          permanent action.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={deleteGame}
                  >
                    Delete
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setDeleteModalOpen(false)}
                    ref={cancelDeleteButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <div>
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <Navbar sidebarOpen={sidebarOpen}>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <div className="flex flex-row justify-between">
              <h1 className="text-2xl font-semibold text-gray-900">Pricings</h1>
              <button
                onClick={() => setAddModalOpen(true)}
                type="button"
                className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <CurrencyDollarIcon
                  className="-ml-0.5 mr-2 h-4 w-4"
                  aria-hidden="true"
                />
                New Pricing
              </button>
            </div>
          </div>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            {/* Replace with your content */}
            <div className="py-4">
              <div className="bg-white shadow overflow-hidden sm:rounded-md">
                {loadingPricing ? (
                  <LoadingIcon />
                ) : pricings.length === 0 ? (
                  <div className="text-center p-12">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="mx-auto h-12 w-12 text-gray-400"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z"
                      />
                    </svg>
                    <h3 className="mt-2 text-sm font-medium text-gray-900">
                      No pricings
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      Get started by creating a new pricing.
                    </p>
                    <div className="mt-6">
                      <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={() => setAddModalOpen(true)}
                      >
                        <PlusIcon
                          className="-ml-1 mr-2 h-5 w-5"
                          aria-hidden="true"
                        />
                        New Pricing
                      </button>
                    </div>
                  </div>
                ) : (
                  <ul className="divide-y divide-gray-200">
                    {pricings.map((pricing) => (
                      <li key={pricing.id}>
                        <Link
                          to={"/pricing/" + pricing.id}
                          className="block hover:bg-gray-50"
                        >
                          <div className="px-4 py-4 sm:px-6">
                            <div className="flex items-center justify-between">
                              <p className="text-sm font-medium text-indigo-600 truncate">
                                {pricing.name_en}
                              </p>
                              <div className="ml-2 flex-shrink-0 flex">
                                <p className="flex items-center text-sm text-gray-500">
                                  <CurrencyDollarIcon
                                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                  {pricing.amount} SDG/month
                                </p>
                              </div>
                            </div>
                            <div className="mt-2 sm:flex sm:justify-between">
                              <div className="sm:flex">
                                <p className="text-gray-400">
                                  {pricing.description_en}
                                </p>
                              </div>
                              <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                                <PlusSmIcon
                                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                                <p>Created at {pricing.createdAt}</p>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
            {/* /End replace */}
          </div>
        </Navbar>
      </div>
    </>
  );
}
