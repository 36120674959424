import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import LoadingButtonIcon from "../LoadingButtonIcon";
import * as gameServices from "./../../services/game.service";

export default function GameDescriptionEdit({ game }) {
  const [edit, setEdit] = useState(false);
  const [submittingEdit, setSubmittingEdit] = useState(false);
  const [localGame, setLocalGame] = useState(game);

  const EditSchema = Yup.object().shape({
    description_ar: Yup.string()
      .min(3, "Game description (arabic) must be 3 characters at minimum")
      .required("Game description (arabic) is required"),
    description_en: Yup.string()
      .min(3, "Game description (english) must be 3 characters at minimum")
      .required("Game description (english) is required"),
  });

  const handleEdit = (description_en, description_ar) => {
    setSubmittingEdit(true);
    let requestGame = game;
    requestGame.description_ar = description_ar;
    requestGame.description_en = description_en;
    gameServices.edit(localGame.id, requestGame).then((response) => {
      setSubmittingEdit(false);
      if (response.status === 200) {
        setLocalGame(response.data.data);
        setEdit(false);
      } else {
        // TODO: handle error
      }
    });
  };

  return (
    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
      <dt className="text-sm font-medium text-gray-500">Description</dt>
      <dd className="mt-1 flex items-center text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        <span className="flex-grow">
          {edit ? (
            <Formik
              initialValues={{
                description_ar: localGame.description_ar,
                description_en: localGame.description_en,
              }}
              validationSchema={EditSchema}
              onSubmit={(values) =>
                handleEdit(values.description_en, values.description_ar)
              }
            >
              {({ touched, errors }) => (
                <Form>
                  <div className="flex flex-row items-center">
                    <Field
                      type="text"
                      name="description_en"
                      id="description_en"
                      className={`shadow-sm block sm:text-sm rounded-md ${
                        touched.description_en && errors.description_en
                          ? "focus:ring-red-500 focus:border-red-500 border-red-500"
                          : "focus:ring-indigo-500 focus:border-indigo-500 border-gray-300"
                      }`}
                      disabled={submittingEdit}
                    />
                    <Field
                      type="text"
                      name="description_ar"
                      id="description_ar"
                      className={`ml-2 shadow-sm block sm:text-sm rounded-md ${
                        touched.description_ar && errors.description_ar
                          ? "focus:ring-red-500 focus:border-red-500 border-red-500"
                          : "focus:ring-indigo-500 focus:border-indigo-500 border-gray-300"
                      }`}
                      disabled={submittingEdit}
                    />
                    <button
                      type="submit"
                      className="ml-2 px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-75"
                      disabled={submittingEdit}
                    >
                      {submittingEdit ? (
                        <LoadingButtonIcon color="GRAY" />
                      ) : (
                        "Save Changes"
                      )}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          ) : (
            localGame.description_en + " - " + localGame.description_ar
          )}
        </span>
        <span className="ml-4 flex-shrink-0">
          <button
            onClick={() => setEdit(!edit)}
            type="button"
            className="mr-4 rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {edit ? "Close" : "Update"}
          </button>
        </span>
      </dd>
    </div>
  );
}
