import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../utils/common";

export default function LoadingButtonIcon({ color = "PRIMARY" }) {
  return (
    <div className="flex items-center justify-center h-5 w-5">
      <SVG
        className="animate-spin"
        src={
          color === "GRAY"
            ? toAbsoluteUrl("/media/button-icon-gray.svg")
            : toAbsoluteUrl("/media/button-icon.svg")
        }
        style={{
          width: 50,
          height: 50,
        }}
      />
    </div>
  );
}
