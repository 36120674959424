import axios from "axios";
import { authHeader } from "../utils/authHeader";

const apiUrl = process.env.REACT_APP_API_URL;

export const subscriptionsByDateRange = (startDate, endDate) => {
  return axios.get(
    apiUrl + "/report/subscriptions/date-range/" + startDate + "/" + endDate,
    {
      headers: authHeader(),
    }
  );
};

export const subscriptionsByDate = (date) => {
  return axios.get(apiUrl + "/report/subscriptions/day/" + date, {
    headers: authHeader(),
  });
};

export const gamesByDateRange = (startDate, endDate) => {
  return axios.get(
    apiUrl + "/report/games/date-range/" + startDate + "/" + endDate,
    {
      headers: authHeader(),
    }
  );
};

export const gamesAllTime = () => {
  return axios.get(apiUrl + "/report/games/all-time/", {
    headers: authHeader(),
  });
};

export const usersByDateRange = (startDate, endDate) => {
  return axios.get(
    apiUrl + "/report/users/date-range/" + startDate + "/" + endDate,
    {
      headers: authHeader(),
    }
  );
};

export const usersByDate = (date) => {
  return axios.get(apiUrl + "/report/users/day/" + date, {
    headers: authHeader(),
  });
};
