import React, { Fragment, useState, useRef, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Sidebar } from "../../partials/Sidebar";
import { Navbar } from "../../partials/Navbar";
import CategoryListItem from "../../components/CategoryListItem";
import {
  ExclamationIcon,
  FolderAddIcon,
  PlusIcon,
} from "@heroicons/react/outline";
import * as categoryService from "../../services/category.service";
import LoadingIcon from "../../components/LoadingIcon";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import LoadingButtonIcon from "../../components/LoadingButtonIcon";

export default function CategoryList() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [currentDeletionCategory, setCurrentDeletionCategory] = useState(null);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [submittingAdd, setSubmittingAdd] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const cancelDeleteButtonRef = useRef(null);
  const cancelAddButtonRef = useRef(null);

  const AddSchema = Yup.object().shape({
    name_en: Yup.string()
      .min(3, "Category name (english) must be 3 characters at minimum")
      .required("Category name (english) is required"),
    name_ar: Yup.string()
      .min(3, "Category name (arabic) must be 3 characters at minimum")
      .required("Category name (arabic) is required"),
  });

  const showDeleteModal = (category) => {
    setDeleteModalOpen(true);
    setCurrentDeletionCategory(category);
  };

  const deleteCategory = () => {
    setDeleting(true);
    categoryService
      .deleteCategory(currentDeletionCategory.id)
      .then((response) => {
        setDeleting(false);
        if (response.status === 200) {
          let newCategories = categories.filter((category) => {
            return category.id !== currentDeletionCategory.id;
          });
          setCategories(newCategories);
          setDeleteModalOpen(false);
        } else {
          // TODO: handle error
        }
      });
  };

  const handleCreate = (name_en, name_ar) => {
    setSubmittingAdd(true);
    categoryService.create(name_en, name_ar).then((response) => {
      setSubmittingAdd(false);
      if (response.status === 200) {
        let newCategories = categories;
        newCategories.push(response.data.data);
        setCategories(newCategories);
        setAddModalOpen(false);
      } else {
        // TODO: handle error
      }
    });
  };

  useEffect(() => {
    categoryService.list().then((response) => {
      if (response.status === 200) {
        setCategories(response.data.data.rows);
        setLoading(false);
      }
    });
  }, []);

  return (
    <>
      <Transition.Root show={addModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelAddButtonRef}
          onClose={setAddModalOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full">
                <Formik
                  initialValues={{ name_en: "", name_ar: "" }}
                  validationSchema={AddSchema}
                  onSubmit={(values) =>
                    handleCreate(values.name_en, values.name_ar)
                  }
                >
                  {({ touched, errors }) => (
                    <Form>
                      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-start">
                          <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-indigo-50 sm:mx-0 sm:h-10 sm:w-10">
                            <FolderAddIcon
                              className="h-6 w-6 text-indigo-500"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                            <Dialog.Title
                              as="h3"
                              className="text-lg leading-6 font-medium text-gray-900"
                            >
                              Add New Category
                            </Dialog.Title>
                            <div className="mt-3 w-full">
                              <div className="grid grid-cols-6 gap-6">
                                <div className="col-span-6 sm:col-span-3">
                                  <label htmlFor="name_en" className="sr-only">
                                    Name (english)
                                  </label>
                                  <Field
                                    type="text"
                                    name="name_en"
                                    id="name_en"
                                    className={`shadow-sm block w-full sm:text-sm rounded-md ${
                                      touched.name_en && errors.name_en
                                        ? "focus:ring-red-500 focus:border-red-500 border-red-500"
                                        : "focus:ring-indigo-500 focus:border-indigo-500 border-gray-300"
                                    }`}
                                    placeholder="Category name (english)"
                                    disabled={submittingAdd}
                                  />
                                </div>
                                <div className="col-span-6 sm:col-span-3">
                                  <label htmlFor="name_ar" className="sr-only">
                                    Name (arabic)
                                  </label>
                                  <Field
                                    type="text"
                                    name="name_ar"
                                    id="name_ar"
                                    className={`shadow-sm block w-full sm:text-sm rounded-md ${
                                      touched.name_ar && errors.name_ar
                                        ? "focus:ring-red-500 focus:border-red-500 border-red-500"
                                        : "focus:ring-indigo-500 focus:border-indigo-500 border-gray-300"
                                    }`}
                                    placeholder="Category name (arabic)"
                                    disabled={submittingAdd}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                        <button
                          type="submit"
                          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-75"
                          disabled={submittingAdd}
                        >
                          {submittingAdd ? <LoadingButtonIcon /> : "Add"}
                        </button>
                        <button
                          type="button"
                          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-75"
                          disabled={submittingAdd}
                          onClick={() => setAddModalOpen(false)}
                          ref={cancelAddButtonRef}
                        >
                          Cancel
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition.Root show={deleteModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelDeleteButtonRef}
          onClose={() => {
            setDeleteModalOpen(false);
            setCurrentDeletionCategory(null);
          }}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        Delete Category
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Are you sure you want to delete this category? This is
                          a permanent action.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-75"
                    onClick={deleteCategory}
                    disabled={deleting}
                  >
                    {deleting ? <LoadingButtonIcon /> : "Delete"}
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-75"
                    onClick={() => {
                      setDeleteModalOpen(false);
                      setCurrentDeletionCategory(null);
                    }}
                    ref={cancelDeleteButtonRef}
                    disabled={deleting}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <div>
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <Navbar sidebarOpen={sidebarOpen}>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <div className="flex flex-row justify-between">
              <h1 className="text-2xl font-semibold text-gray-900">
                Categories
              </h1>
              <button
                onClick={() => setAddModalOpen(true)}
                type="button"
                className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <FolderAddIcon
                  className="-ml-0.5 mr-2 h-4 w-4"
                  aria-hidden="true"
                />
                New Category
              </button>
            </div>
          </div>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            {/* Replace with your content */}
            <div className="py-4">
              <div className="flex flex-col">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow bg-white overflow-hidden border-b border-gray-200 sm:rounded-lg">
                      {loading ? (
                        <LoadingIcon />
                      ) : categories.length === 0 ? (
                        <div className="text-center p-12">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="mx-auto h-12 w-12 text-gray-400"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z"
                            />
                          </svg>
                          <h3 className="mt-2 text-sm font-medium text-gray-900">
                            No categories
                          </h3>
                          <p className="mt-1 text-sm text-gray-500">
                            Get started by creating a new category.
                          </p>
                          <div className="mt-6">
                            <button
                              type="button"
                              className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                              onClick={() => setAddModalOpen(true)}
                            >
                              <PlusIcon
                                className="-ml-1 mr-2 h-5 w-5"
                                aria-hidden="true"
                              />
                              New Category
                            </button>
                          </div>
                        </div>
                      ) : (
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                Name
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                # Games
                              </th>
                              <th scope="col" className="relative px-6 py-3">
                                <span className="sr-only">Edit</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {categories.map((category, categoryIndex) => (
                              <CategoryListItem
                                key={category.id}
                                category={category}
                                evenIndex={categoryIndex % 2 === 0}
                                deleteCategory={showDeleteModal}
                              />
                            ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /End replace */}
          </div>
        </Navbar>
      </div>
    </>
  );
}
