import axios from "axios";
import { authHeader } from "../utils/authHeader";

const apiUrl = process.env.REACT_APP_API_URL;

export const list = () => {
  return axios.get(apiUrl + "/pricing/all/1/1000", { headers: authHeader() });
};

export const get = (id) => {
  return axios.get(apiUrl + "/pricing/" + id, { headers: authHeader() });
};

export const create = (pricing) => {
  console.log("create pricing: ", pricing);
  return axios.post(apiUrl + "/pricing", pricing, { headers: authHeader() });
};

export const edit = (id, pricing) => {
  return axios.put(apiUrl + "/pricing/" + id, pricing, {
    headers: authHeader(),
  });
};

export const deleteCategory = (id) => {
  return axios.delete(apiUrl + "/pricing/" + id, { headers: authHeader() });
};
