import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import LoadingButtonIcon from "./LoadingButtonIcon";
import * as categoryService from "../services/category.service";

export default function CategoryListItem({
  category,
  evenIndex,
  deleteCategory,
}) {
  const [edit, setEdit] = useState(false);
  const [submittingEdit, setSubmittingEdit] = useState(false);
  const [localCategory, setLocalCategory] = useState(category);

  const EditSchema = Yup.object().shape({
    name_ar: Yup.string()
      .min(3, "Category name (arabic) must be 3 characters at minimum")
      .required("Category name (arabic) is required"),
    name_en: Yup.string()
      .min(3, "Category name (english) must be 3 characters at minimum")
      .required("Category name (english) is required"),
  });

  const handleEdit = (name_en, name_ar) => {
    setSubmittingEdit(true);
    categoryService
      .edit(localCategory.id, name_en, name_ar)
      .then((response) => {
        setSubmittingEdit(false);
        if (response.status === 200) {
          setLocalCategory(response.data.data);
          setEdit(false);
        } else {
          // TODO: handle error
        }
      });
  };

  return (
    <tr className={evenIndex ? "bg-white" : "bg-gray-50"}>
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
        {edit ? (
          <Formik
            initialValues={{
              name_ar: localCategory.name_ar,
              name_en: localCategory.name_en,
            }}
            validationSchema={EditSchema}
            onSubmit={(values) => handleEdit(values.name_en, values.name_ar)}
          >
            {({ touched, errors, isSubmitting }) => (
              <Form>
                <div className="flex flex-row items-center">
                  <Field
                    type="text"
                    name="name_en"
                    id="name_en"
                    className={`shadow-sm block sm:text-sm rounded-md ${
                      touched.name_en && errors.name_en
                        ? "focus:ring-red-500 focus:border-red-500 border-red-500"
                        : "focus:ring-indigo-500 focus:border-indigo-500 border-gray-300"
                    }`}
                    disabled={submittingEdit}
                  />
                  <Field
                    type="text"
                    name="name_ar"
                    id="name_ar"
                    className={`ml-2 shadow-sm block sm:text-sm rounded-md ${
                      touched.name_ar && errors.name_ar
                        ? "focus:ring-red-500 focus:border-red-500 border-red-500"
                        : "focus:ring-indigo-500 focus:border-indigo-500 border-gray-300"
                    }`}
                    disabled={submittingEdit}
                  />
                  <button
                    type="submit"
                    className="ml-2 px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-75"
                    disabled={submittingEdit}
                  >
                    {submittingEdit ? (
                      <LoadingButtonIcon color="GRAY" />
                    ) : (
                      "Save Changes"
                    )}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        ) : (
          localCategory.name_en + " - " + localCategory.name_ar
        )}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {localCategory.games ? localCategory.games.length : 0}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
        {localCategory.games ? (
          localCategory.games.length === 0 && (
            <button
              onClick={() => deleteCategory(localCategory)}
              type="button"
              className="text-red-600 hover:text-red-900 mr-3"
            >
              Delete
            </button>
          )
        ) : (
          <button
            onClick={() => deleteCategory(localCategory)}
            type="button"
            className="text-red-600 hover:text-red-900 mr-3"
          >
            Delete
          </button>
        )}
        <button
          onClick={() => setEdit(!edit)}
          type="button"
          className="text-indigo-600 hover:text-indigo-900"
        >
          {edit ? "Close" : "Edit"}
        </button>
      </td>
    </tr>
  );
}
