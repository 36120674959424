import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { Link, useLocation } from "react-router-dom";
import { navigationLinks } from "../utils/navigationLinks";
import { classNames } from "./../utils/common";
import { Disclosure } from "@headlessui/react";

export function Sidebar({ sidebarOpen, setSidebarOpen }) {
  const location = useLocation();

  // return (
  //   <Transition.Root show={sidebarOpen} as={Fragment}>
  //     <Dialog
  //       as="div"
  //       className="fixed inset-0 flex z-40 md:hidden"
  //       onClose={setSidebarOpen}
  //     >
  //       <Transition.Child
  //         as={Fragment}
  //         enter="transition-opacity ease-linear duration-300"
  //         enterFrom="opacity-0"
  //         enterTo="opacity-100"
  //         leave="transition-opacity ease-linear duration-300"
  //         leaveFrom="opacity-100"
  //         leaveTo="opacity-0"
  //       >
  //         <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
  //       </Transition.Child>
  //       <Transition.Child
  //         as={Fragment}
  //         enter="transition ease-in-out duration-300 transform"
  //         enterFrom="-translate-x-full"
  //         enterTo="translate-x-0"
  //         leave="transition ease-in-out duration-300 transform"
  //         leaveFrom="translate-x-0"
  //         leaveTo="-translate-x-full"
  //       >
  //         <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
  //           <Transition.Child
  //             as={Fragment}
  //             enter="ease-in-out duration-300"
  //             enterFrom="opacity-0"
  //             enterTo="opacity-100"
  //             leave="ease-in-out duration-300"
  //             leaveFrom="opacity-100"
  //             leaveTo="opacity-0"
  //           >
  //             <div className="absolute top-0 right-0 -mr-12 pt-2">
  //               <button
  //                 type="button"
  //                 className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
  //                 onClick={() => setSidebarOpen(false)}
  //               >
  //                 <span className="sr-only">Close sidebar</span>
  //                 <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
  //               </button>
  //             </div>
  //           </Transition.Child>
  //           <div className="flex-shrink-0 flex items-center px-4">
  //             <img
  //               className="h-8 w-auto"
  //               src="https://tailwindui.com/img/logos/workflow-logo-indigo-600-mark-gray-800-text.svg"
  //               alt="Workflow"
  //             />
  //           </div>
  //           <div className="mt-5 flex-1 h-0 overflow-y-auto">
  //             <nav className="px-2 space-y-1">
  //               {navigationLinks.map((item) => (
  //                 <Link
  //                   key={item.name}
  //                   to={item.href}
  //                   className={classNames(
  //                     item.href === location.pathname
  //                       ? "bg-gray-100 text-gray-900"
  //                       : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
  //                     "group flex items-center px-2 py-2 text-base font-medium rounded-md"
  //                   )}
  //                 >
  //                   <item.icon
  //                     className={classNames(
  //                       item.href === location.pathname
  //                         ? "text-gray-500"
  //                         : "text-gray-400 group-hover:text-gray-500",
  //                       "mr-4 flex-shrink-0 h-6 w-6"
  //                     )}
  //                     aria-hidden="true"
  //                   />
  //                   {item.name}
  //                 </Link>
  //               ))}
  //             </nav>
  //           </div>
  //         </div>
  //       </Transition.Child>
  //       <div className="flex-shrink-0 w-14" aria-hidden="true">
  //         {/* Dummy element to force sidebar to shrink to fit close icon */}
  //       </div>
  //     </Dialog>
  //   </Transition.Root>
  // );

  // return (
  //   <div className="flex flex-col flex-grow border-r border-gray-200 pt-5 pb-4 bg-white overflow-y-auto">
  //     <div className="flex items-center flex-shrink-0 px-4">
  //       <img
  //         className="h-8 w-auto"
  //         src="https://tailwindui.com/img/logos/workflow-logo-indigo-600-mark-gray-800-text.svg"
  //         alt="Workflow"
  //       />
  //     </div>
  //     <div className="mt-5 flex-grow flex flex-col">
  //       <nav className="flex-1 px-2 space-y-1 bg-white" aria-label="Sidebar">
  //         {navigationLinks.map((item) =>
  //           !item.children ? (
  //             <div key={item.name}>
  //               <a
  //                 href={item.href}
  //                 className={classNames(
  //                   item.current
  //                     ? "bg-gray-100 text-gray-900"
  //                     : "bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900",
  //                   "group w-full flex items-center pl-7 pr-2 py-2 text-sm font-medium rounded-md"
  //                 )}
  //               >
  //                 {item.name}
  //               </a>
  //             </div>
  //           ) : (
  //             <Disclosure as="div" key={item.name} className="space-y-1">
  //               {({ open }) => (
  //                 <>
  //                   <Disclosure.Button
  //                     className={classNames(
  //                       item.href === location.pathname
  //                         ? "bg-gray-100 text-gray-900"
  //                         : "bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900",
  //                       "group w-full flex items-center pr-2 py-2 text-left text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
  //                     )}
  //                   >
  //                     <svg
  //                       className={classNames(
  //                         open ? "text-gray-400 rotate-90" : "text-gray-300",
  //                         "mr-2 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150"
  //                       )}
  //                       viewBox="0 0 20 20"
  //                       aria-hidden="true"
  //                     >
  //                       <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
  //                     </svg>
  //                     {item.name}
  //                   </Disclosure.Button>
  //                   <Disclosure.Panel className="space-y-1">
  //                     {item.children.map((subItem) => (
  //                       <Disclosure.Button
  //                         key={subItem.name}
  //                         as="a"
  //                         href={subItem.href}
  //                         className="group w-full flex items-center pl-10 pr-2 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50"
  //                       >
  //                         {subItem.name}
  //                       </Disclosure.Button>
  //                     ))}
  //                   </Disclosure.Panel>
  //                 </>
  //               )}
  //             </Disclosure>
  //           )
  //         )}
  //       </nav>
  //     </div>
  //   </div>
  // );

  return <></>;
}
