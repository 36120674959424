import axios from "axios";
import { guestHeader } from "./../utils/authHeader";

const apiUrl = process.env.REACT_APP_API_URL;

export const login = (phoneNumber, password) => {
  return axios.post(
    apiUrl + "/auth/admin/login",
    { phoneNumber, password },
    {
      headers: guestHeader(),
    }
  );
};
