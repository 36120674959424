import React, { Fragment, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationIcon } from "@heroicons/react/outline";
import GameList from "./pages/Game/GameList";
import GameView from "./pages/Game/GameView";
import Home from "./pages/Home";
import CategoryList from "./pages/Category/CategoryList";
import UserList from "./pages/User/UserList";
import Login from "./pages/Auth/Login";
import ProtectedRoute from "./components/ProtectedRoute";
import PricingList from "./pages/Pricing/PricingList";
import PricingView from "./pages/Pricing/PricingView";
import SubscriptionsReport from "./pages/Report/SubscriptionsReport";
import GamesReport from "./pages/Report/GamesReport/index";
import UsersReport from "./pages/Report/UsersReport/index";

function App() {
  const [errorModalOpen, setErrorModalOpen] = useState(false);

  const error = useSelector((state) => state.app.error);
  const errorTitle = useSelector((state) => state.app.errorTitle);
  const errorMessage = useSelector((state) => state.app.errorMessage);

  return (
    <>
      {error && (
        <Transition.Root show={errorModalOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed z-10 inset-0 overflow-y-auto"
            onClose={setErrorModalOpen}
          >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                  <div>
                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                      <ExclamationIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        {errorTitle}
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">{errorMessage}</p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6">
                    <button
                      type="button"
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:col-start-1 sm:text-sm"
                      onClick={setErrorModalOpen}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      )}

      <Routes>
        <Route path="/login" element={<Login />} />

        <Route
          path="/games"
          element={
            <ProtectedRoute>
              <GameList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/game/:id"
          element={
            <ProtectedRoute>
              <GameView />
            </ProtectedRoute>
          }
        />

        <Route
          path="/categories"
          element={
            <ProtectedRoute>
              <CategoryList />
            </ProtectedRoute>
          }
        />

        <Route
          path="/pricings"
          element={
            <ProtectedRoute>
              <PricingList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/pricing/:id"
          element={
            <ProtectedRoute>
              <PricingView />
            </ProtectedRoute>
          }
        />

        <Route
          path="/users"
          element={
            <ProtectedRoute>
              <UserList />
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/report/subscriptions"
          element={
            <ProtectedRoute>
              <SubscriptionsReport />
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/report/games"
          element={
            <ProtectedRoute>
              <GamesReport />
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/report/users"
          element={
            <ProtectedRoute>
              <UsersReport />
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        />
      </Routes>
    </>
  );
}

export default App;
