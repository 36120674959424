import React, { Fragment, useState, useRef, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Sidebar } from "../../partials/Sidebar";
import { Navbar } from "../../partials/Navbar";
import { useParams } from "react-router-dom";
import { ExclamationIcon } from "@heroicons/react/outline";
import * as pricingService from "../../services/pricing.service";
import LoadingIcon from "../../components/LoadingIcon";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

export default function PricingView() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [editEnName, setEditEnName] = useState(false);
  const [editArName, setEditArName] = useState(false);
  const [editAmount, setEditAmount] = useState(false);
  const [editInterval, setEditInterval] = useState(false);
  const [loading, setLoading] = useState(true);
  const [pricing, setPricing] = useState(null);
  const [pricingNotFound, setPricingNotFound] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const cancelDeleteButtonRef = useRef(null);

  const PricingNameSchema = Yup.object().shape({
    name_en: Yup.string()
      .min(2, "Pricing name must be 4 characters at minimum")
      .required("Category name is required"),
    name_ar: Yup.string()
      .min(2, "Pricing name must be 4 characters at minimum")
      .required("Category name is required"),
  });

  const PricingAmountSchema = Yup.object().shape({
    amount: Yup.string().required("Amount name is required"),
  });

  const PricingIntervalSchema = Yup.object().shape({
    interval: Yup.string().required("Interval is required"),
  });

  const PricingDescriptionSchema = Yup.object().shape({
    description: Yup.string().required("Pricing description is required"),
  });

  const { id } = useParams();

  const deletePricing = () => {};

  const handleNameChange = () => {};
  const handleAmountChange = () => {};
  const handleIntervalChange = () => {};
  const handleDescriptionChange = () => {};

  useEffect(() => {
    if (id) {
      // setPricing({
      //   id: 1,
      //   name: "Pro",
      //   active: true,
      //   amount: "59.99",
      //   interval: "2",
      //   period: "month",
      //   closeDate: "2020-01-07",
      //   closeDateFull: "January 7, 2020",
      // });
      // setLoading(false);

      pricingService.get(id).then((response) => {
        if (response.status === 200) {
          setPricing(response.data.data);
          setLoading(false);
        } else if (response.status === 404) {
          setPricingNotFound(true);
          setLoading(false);
        }
      });
    }
  }, []);

  return (
    <>
      <Transition.Root show={deleteModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelDeleteButtonRef}
          onClose={setDeleteModalOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        Delete Pricing
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Are you sure you want to delete this pricing? This is
                          a permanent action.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={deletePricing}
                  >
                    Delete
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setDeleteModalOpen(false)}
                    ref={cancelDeleteButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <div>
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <Navbar sidebarOpen={sidebarOpen}>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <h1 className="text-2xl font-semibold text-gray-900">Pricings</h1>
          </div>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            {/* Replace with your content */}
            <div className="py-4">
              <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                {loading ? (
                  <LoadingIcon />
                ) : pricingNotFound ? (
                  <div className="text-center p-8">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="mx-auto h-12 w-12 text-gray-400"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M10 21h7a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v11m0 5l4.879-4.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242z"
                      />
                    </svg>
                    <h3 className="mt-2 text-sm font-medium text-gray-900">
                      No Pricing Found
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      This pricing is ethier not exist or deleted.
                    </p>
                  </div>
                ) : (
                  <>
                    <div className="px-4 py-5 sm:px-6 flex flex-row justify-between items-center">
                      <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                          Pricing Information
                        </h3>
                        <p className="mt-1 max-w-2xl text-sm text-gray-500">
                          Technical informations and description.
                        </p>
                      </div>
                      <div>
                        <button
                          type="button"
                          onClick={() => {}}
                          className="mr-3 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Disable Pricing
                        </button>
                        <button
                          type="button"
                          onClick={() => setDeleteModalOpen(true)}
                          className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-red-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Delete Pricing
                        </button>
                      </div>
                    </div>
                    <div className="border-t border-gray-200">
                      <dl>
                        <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-gray-500">
                            English Name
                          </dt>
                          <dd className="mt-1 flex items-center text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            <span className="flex-grow">
                              {editEnName ? (
                                <Formik
                                  initialValues={{
                                    name_en: pricing.name_en,
                                  }}
                                  validationSchema={PricingNameSchema}
                                  onSubmit={(values) =>
                                    handleNameChange(values.name_en)
                                  }
                                >
                                  {({ touched, errors }) => (
                                    <Form className="flex flex-row items-center">
                                      <Field
                                        type="text"
                                        name="name_en"
                                        id="name_en"
                                        className={`shadow-sm block sm:text-sm rounded-md ${
                                          touched.name_en && errors.name_en
                                            ? "focus:ring-red-500 focus:border-red-500 border-red-300"
                                            : "focus:ring-indigo-500 focus:border-indigo-500 border-gray-300"
                                        }`}
                                      />
                                      <button
                                        type="submit"
                                        className="ml-2 px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                      >
                                        Save Changes
                                      </button>
                                    </Form>
                                  )}
                                </Formik>
                              ) : (
                                pricing.name_en
                              )}
                            </span>
                            <span className="ml-4 flex-shrink-0">
                              <button
                                onClick={() => setEditEnName(!editEnName)}
                                type="button"
                                className="mr-4 rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                              >
                                {editEnName ? "Close" : "Update"}
                              </button>
                            </span>
                          </dd>
                        </div>
                        <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-gray-500">
                            Arabic Name
                          </dt>
                          <dd className="mt-1 flex items-center text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            <span className="flex-grow">
                              {editArName ? (
                                <Formik
                                  initialValues={{
                                    name_ar: pricing.name_ar,
                                  }}
                                  validationSchema={PricingNameSchema}
                                  onSubmit={(values) =>
                                    handleNameChange(values.name_ar)
                                  }
                                >
                                  {({ touched, errors }) => (
                                    <Form className="flex flex-row items-center">
                                      <Field
                                        type="text"
                                        name="name_ar"
                                        id="name_ar"
                                        className={`shadow-sm block sm:text-sm rounded-md ${
                                          touched.name_ar && errors.name_ar
                                            ? "focus:ring-red-500 focus:border-red-500 border-red-300"
                                            : "focus:ring-indigo-500 focus:border-indigo-500 border-gray-300"
                                        }`}
                                      />
                                      <button
                                        type="submit"
                                        className="ml-2 px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                      >
                                        Save Changes
                                      </button>
                                    </Form>
                                  )}
                                </Formik>
                              ) : (
                                pricing.name_ar
                              )}
                            </span>
                            <span className="ml-4 flex-shrink-0">
                              <button
                                onClick={() => setEditArName(!editArName)}
                                type="button"
                                className="mr-4 rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                              >
                                {editArName ? "Close" : "Update"}
                              </button>
                            </span>
                          </dd>
                        </div>

                        <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-gray-500">
                            Amount
                          </dt>
                          <dd className="mt-1 flex items-center text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            <span className="flex-grow">
                              {editAmount ? (
                                <Formik
                                  initialValues={{
                                    amount: pricing.amount,
                                  }}
                                  validationSchema={PricingAmountSchema}
                                  onSubmit={(values) =>
                                    handleAmountChange(values.amount)
                                  }
                                >
                                  {({ touched, errors }) => (
                                    <Form className="flex flex-row items-center">
                                      <Field
                                        type="text"
                                        name="amount"
                                        id="amount"
                                        className={`shadow-sm block sm:text-sm rounded-md ${
                                          touched.amount && errors.amount
                                            ? "focus:ring-red-500 focus:border-red-500 border-red-300"
                                            : "focus:ring-indigo-500 focus:border-indigo-500 border-gray-300"
                                        }`}
                                      />
                                      <button
                                        type="submit"
                                        className="ml-2 px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                      >
                                        Save Changes
                                      </button>
                                    </Form>
                                  )}
                                </Formik>
                              ) : (
                                pricing.amount
                              )}
                            </span>
                            <span className="ml-4 flex-shrink-0">
                              <button
                                onClick={() => setEditAmount(!editAmount)}
                                type="button"
                                className="mr-4 rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                              >
                                {editAmount ? "Close" : "Update"}
                              </button>
                            </span>
                          </dd>
                        </div>

                        <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-gray-500">
                            Interval
                          </dt>
                          <dd className="mt-1 flex items-center text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            <span className="flex-grow">
                              {editInterval ? (
                                <Formik
                                  initialValues={{
                                    interval: pricing.interval,
                                  }}
                                  validationSchema={PricingIntervalSchema}
                                  onSubmit={(values) =>
                                    handleIntervalChange(values.interval)
                                  }
                                >
                                  {({ touched, errors }) => (
                                    <Form className="flex flex-row items-center">
                                      <label
                                        htmlFor="interval"
                                        className="sr-only"
                                      >
                                        Interval
                                      </label>
                                      <Field
                                        as="select"
                                        id="interval"
                                        name="interval"
                                        className={`block pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none sm:text-sm rounded-md ${
                                          touched.interval && errors.interval
                                            ? "focus:ring-red-500 focus:border-red-500"
                                            : "focus:ring-indigo-500 focus:border-indigo-500"
                                        }`}
                                      >
                                        <option value="month">Month</option>
                                        <option value="year">Year</option>
                                      </Field>
                                      <button
                                        type="submit"
                                        className="ml-2 px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                      >
                                        Save Changes
                                      </button>
                                    </Form>
                                  )}
                                </Formik>
                              ) : (
                                <>
                                  {pricing.interval}{" "}
                                  <span className="text-gray-500 text-xs capitalize">
                                    {pricing.period}
                                  </span>
                                </>
                              )}
                            </span>
                            <span className="ml-4 flex-shrink-0">
                              <button
                                onClick={() => setEditInterval(!editInterval)}
                                type="button"
                                className="mr-4 rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                              >
                                {editInterval ? "Close" : "Update"}
                              </button>
                            </span>
                          </dd>
                        </div>
                      </dl>
                    </div>{" "}
                  </>
                )}
                {/* End content */}
              </div>
            </div>
            {/* /End replace */}
          </div>
        </Navbar>
      </div>
    </>
  );
}
