import React, { useState } from "react";
import { Sidebar } from "../../../partials/Sidebar";
import { Navbar } from "../../../partials/Navbar";
import { classNames } from "../../../utils/common";
import DateRange from "./DateRange";
import Year from "./Year";
import Month from "./Month";

export default function SubscriptionsReport() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [tabs, setTabs] = useState([
    { name: "Month", current: false },
    { name: "Year", current: false },
    { name: "Date range", current: true },
  ]);

  const changeTab = (tabIndex) => {
    let newTabs = tabs.map((tempTab, tempTabIndex) => {
      return tempTabIndex === tabIndex
        ? {
            name: tempTab.name,
            current: true,
          }
        : {
            name: tempTab.name,
            current: false,
          };
    });
    setTabs(newTabs);
  };

  return (
    <>
      <div>
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <Navbar sidebarOpen={sidebarOpen}>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <h1 className="text-2xl font-semibold text-gray-900">
              Subscriptions report
            </h1>
          </div>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <div className="py-4">
              <div className="max-w-2xl mx-auto">
                <div className="sm:hidden">
                  <label htmlFor="tabs" className="sr-only">
                    Select a tab
                  </label>
                  {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                  <select
                    id="tabs"
                    name="tabs"
                    className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                    defaultValue={tabs.find((tab) => tab.current).name}
                  >
                    {tabs.map((tab) => (
                      <option key={tab.name}>{tab.name}</option>
                    ))}
                  </select>
                </div>
                <div className="hidden sm:block">
                  <nav
                    className="relative z-0 rounded-lg shadow flex divide-x divide-gray-200"
                    aria-label="Tabs"
                  >
                    {tabs.map((tab, tabIdx) => (
                      <button
                        key={tab.name}
                        onClick={() => changeTab(tabIdx)}
                        className={classNames(
                          tab.current
                            ? "text-gray-900"
                            : "text-gray-500 hover:text-gray-700",
                          tabIdx === 0 ? "rounded-l-lg" : "",
                          tabIdx === tabs.length - 1 ? "rounded-r-lg" : "",
                          "group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
                        )}
                        aria-current={tab.current ? "page" : undefined}
                      >
                        <span>{tab.name}</span>
                        <span
                          aria-hidden="true"
                          className={classNames(
                            tab.current ? "bg-indigo-500" : "bg-transparent",
                            "absolute inset-x-0 bottom-0 h-0.5"
                          )}
                        />
                      </button>
                    ))}
                  </nav>
                </div>
              </div>
              <div className="bg-white overflow-hidden shadow rounded-lg mt-4">
                <div className="px-4 py-5 sm:p-6">
                  {tabs[0].current && <Month />}
                  {tabs[1].current && <Year />}
                  {tabs[2].current && <DateRange />}
                </div>
              </div>
            </div>
          </div>
        </Navbar>
      </div>
    </>
  );
}
