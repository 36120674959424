import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import * as reportService from "../../../services/report.service";
import "react-datepicker/dist/react-datepicker.css";
import Chart from "react-apexcharts";
import { formatDateToString } from "./../../../utils/common";

export default function AllTime() {
  const now = new Date();
  const [startDate, setStartDate] = useState(
    new Date(now.getFullYear(), now.getMonth(), now.getDate() - 120)
  );
  const [endDate, setEndDate] = useState(now);
  const [counts, setCounts] = useState([]);
  const [names, setNames] = useState([]);
  const [noData, setNoDate] = useState(false);

  const series = counts;
  const options = {
    labels: names,
    chart: {
      type: "polarArea",
    },
    stroke: {
      colors: ["#fff"],
    },
    fill: {
      opacity: 0.8,
    },
    title: {
      text: "Most played games by date range",
      align: "left",
      style: {
        fontFamily: "Segoe UI",
        fontWeight: 600,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  const structureData = (data) => {
    let countSeries = new Array(data.length).fill(0);
    let nameSeries = new Array(data.length).fill(0);
    data.forEach((record, recordIdx) => {
      countSeries[recordIdx] = record.count;
      nameSeries[recordIdx] = record.game.name_en;
    });

    return { countSeries, nameSeries };
  };

  const createReport = () => {
    reportService
      .gamesAllTime()
      .then((repsonse) => {
        if (repsonse.data.data && repsonse.data.data.length > 0) {
          setNoDate(false);
          let { countSeries, nameSeries } = structureData(repsonse.data.data);
          setCounts(countSeries);
          setNames(nameSeries);
        } else {
          setNoDate(true);
        }
      })
      .catch((error) => console.log("error: ", error));
  };

  useEffect(() => {
    createReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="flex">
        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
        />
        <DatePicker
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
        />
      </div>
      <div className="mt-4">
        {noData ? (
          <div className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="mx-auto h-12 w-12 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z"
              />
            </svg>
            <span className="mt-2 block text-sm font-medium text-gray-900">
              No data for the specified date range.
            </span>
          </div>
        ) : (
          <div className="mt-8">
            <Chart options={options} series={series} type="pie" height={350} />
          </div>
        )}
      </div>
    </>
  );
}
