import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import LoadingButtonIcon from "../LoadingButtonIcon";
import * as gameServices from "./../../services/game.service";
import * as categoryService from "../../services/category.service";

export default function GameCategoryEdit({ game }) {
  const [edit, setEdit] = useState(false);
  const [submittingEdit, setSubmittingEdit] = useState(false);
  const [localGame, setLocalGame] = useState(game);
  const [categories, setCategories] = useState(null);

  const EditSchema = Yup.object().shape({
    category_id: Yup.number().required("Game category is required"),
  });

  const handleEdit = (categoryId) => {
    setSubmittingEdit(true);
    let requestGame = game;
    requestGame.categoryId = categoryId;
    gameServices.edit(localGame.id, requestGame).then((response) => {
      setSubmittingEdit(false);
      if (response.status === 200) {
        setLocalGame(response.data.data);
        console.log("response.data.data: ", response.data.data);
        console.log("localGame: ", localGame);
        setEdit(false);
      } else {
        // TODO: handle error
      }
    });
  };

  const getCategories = () => {
    categoryService.list().then((response) => {
      if (response.status === 200) {
        setCategories(response.data.data.rows);
      }
    });
  };

  return (
    <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
      <dt className="text-sm font-medium text-gray-500">Category</dt>
      <dd className="mt-1 flex items-center text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        <span className="flex-grow">
          {edit ? (
            categories && categories.length > 0 ? (
              <Formik
                initialValues={{
                  category_id: localGame.categoryId,
                }}
                validationSchema={EditSchema}
                onSubmit={(values) => handleEdit(values.category_id)}
              >
                {({ touched, errors }) => (
                  <Form>
                    <div className="flex flex-row items-center">
                      <Field
                        as="select"
                        id="category_id"
                        name="category_id"
                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md w-36 md:w-1/3"
                      >
                        {categories.map((category) => (
                          <option key={category.id} value={category.id}>
                            {category.name_en}
                          </option>
                        ))}
                      </Field>
                      <button
                        type="submit"
                        className="ml-2 px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-75"
                        disabled={submittingEdit}
                      >
                        {submittingEdit ? (
                          <LoadingButtonIcon color="GRAY" />
                        ) : (
                          "Save Changes"
                        )}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            ) : (
              <LoadingButtonIcon color="GRAY" />
            )
          ) : (
            localGame.category.name_en
          )}
        </span>
        <span className="ml-4 flex-shrink-0">
          <button
            onClick={() => {
              getCategories();
              setEdit(!edit);
            }}
            type="button"
            className="mr-4 rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {edit ? "Close" : "Update"}
          </button>
        </span>
      </dd>
    </div>
  );
}
