import axios from "axios";
import { authHeader } from "../utils/authHeader";

const apiUrl = process.env.REACT_APP_API_URL;

export const list = () => {
  return axios.get(apiUrl + "/category/all/1/1000", {
    headers: authHeader(),
  });
};

export const get = (id) => {
  return axios.get(apiUrl + "/category/" + id, {
    headers: authHeader(),
  });
};

export const create = (name_en, name_ar) => {
  return axios.post(
    apiUrl + "/category",
    { name_en, name_ar },
    {
      headers: authHeader(),
    }
  );
};

export const edit = (id, name_en, name_ar) => {
  return axios.put(
    apiUrl + "/category/" + id,
    { name_en, name_ar },
    {
      headers: authHeader(),
    }
  );
};

export const deleteCategory = (id) => {
  return axios.delete(apiUrl + "/category/" + id, {
    headers: authHeader(),
  });
};
