import axios from "axios";
import { authHeader } from "../utils/authHeader";

const apiUrl = process.env.REACT_APP_API_URL;

export const list = () => {
  return axios.get(apiUrl + "/user/all/1/1000", {
    headers: authHeader(),
  });
};

export const get = (id) => {
  return axios.get(apiUrl + "/game/" + id, {
    headers: authHeader(),
  });
};
