import React, { Fragment, useState, useEffect, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import LoadingButtonIcon from "../../components/LoadingButtonIcon";
import { Sidebar } from "../../partials/Sidebar";
import { Navbar } from "../../partials/Navbar";
import { Link } from "react-router-dom";
import * as gameService from "../../services/game.service";
import * as categoryService from "../../services/category.service";
import { FolderIcon, PlusIcon, UserGroupIcon } from "@heroicons/react/outline";
import { ExclamationIcon } from "@heroicons/react/solid";
import LoadingIcon from "../../components/LoadingIcon";
import {
  MAX_IMAGE_FILE_SIZE,
  SUPPORTED_IMAGE_FORMATS,
} from "../../utils/common";

export default function GameList() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [games, setGames] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [submittingAdd, setSubmittingAdd] = useState(false);

  const cancelAddButtonRef = useRef(null);

  const AddSchema = Yup.object().shape({
    name_ar: Yup.string()
      .min(3, "Game name in arabic must be 3 characters at minimum")
      .required("Game name in arabic is required"),
    name_en: Yup.string()
      .min(3, "Game name in english must be 3 characters at minimum")
      .required("Game name in english is required"),
    description_ar: Yup.string()
      .min(3, "Game description in arabic must be 3 characters at minimum")
      .required("Game description in arabic is required"),
    description_en: Yup.string()
      .min(3, "Game description in english must be 3 characters at minimum")
      .required("Game description in english is required"),
    url: Yup.string()
      .min(3, "Game url must be 3 characters at minimum")
      .required("Game url is required"),
    image: Yup.mixed()
      .test("fileRequired", "Game image is required", (value) => value)
      .test(
        "fileSize",
        "File is too large",
        (value) => value && value.size <= MAX_IMAGE_FILE_SIZE
      )
      .test(
        "fileType",
        "Your Error Message",
        (value) =>
          !value || (value && SUPPORTED_IMAGE_FORMATS.includes(value?.type))
      ),
    thumbnail: Yup.mixed()
      .required("Game thumbnail is required.")
      .test(
        "fileSize",
        "File is too large",
        (value) => value && value.size <= MAX_IMAGE_FILE_SIZE
      )
      .test(
        "fileType",
        "Your Error Message",
        (value) =>
          !value || (value && SUPPORTED_IMAGE_FORMATS.includes(value?.type))
      ),
  });

  const getCategories = () => {
    setLoadingCategories(true);
    categoryService.list().then((response) => {
      if (response.status === 200) {
        setCategories(response.data.data.rows);
        setLoadingCategories(false);
      }
    });
  };

  const handleCreate = (values) => {
    setSubmittingAdd(true);
    gameService.create(values).then((response) => {
      setSubmittingAdd(false);
      if (response.status === 200) {
        getGamesList();
        setAddModalOpen(false);
      } else {
        // TODO: handle error
      }
    });
  };

  const getGamesList = () => {
    setLoading(true);
    gameService.list().then((response) => {
      if (response.status === 200) {
        setGames(response.data.data.rows);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    getGamesList();
  }, []);

  return (
    <>
      <Transition.Root show={addModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelAddButtonRef}
          onClose={setAddModalOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full">
                {loadingCategories ? (
                  <LoadingIcon />
                ) : categories.length === 0 ? (
                  <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <ExclamationIcon
                          className="h-5 w-5 text-yellow-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-3">
                        <p className="text-sm text-yellow-700">
                          No game categories found!{" "}
                          <Link
                            to="/categories"
                            className="font-medium underline text-yellow-700 hover:text-yellow-600"
                          >
                            Add some categories.
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <Formik
                    initialValues={{
                      name_ar: "",
                      name_en: "",
                      description_ar: "",
                      description_en: "",
                      url: "",
                      category_id: categories[0].id,
                      image: null,
                      thumbnail: null,
                    }}
                    validationSchema={AddSchema}
                    onSubmit={(values) => handleCreate(values)}
                  >
                    {(formikProps) => (
                      <Form>
                        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                          <div className="sm:flex sm:items-start">
                            <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-indigo-50 sm:mx-0 sm:h-10 sm:w-10">
                              <PlusIcon
                                className="h-6 w-6 text-indigo-500"
                                aria-hidden="true"
                              />
                            </div>
                            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                              <Dialog.Title
                                as="h3"
                                className="text-lg leading-6 font-medium text-gray-900"
                              >
                                Add New Game
                              </Dialog.Title>
                              <div className="mt-3 w-full">
                                <div className="grid grid-cols-6 gap-6">
                                  <div className="col-span-6 sm:col-span-3">
                                    <label
                                      htmlFor="name_ar"
                                      className="block text-sm font-medium text-gray-700"
                                    >
                                      Name (arabic)
                                    </label>
                                    <div className="mt-1">
                                      <Field
                                        type="text"
                                        name="name_ar"
                                        id="name_ar"
                                        className={`shadow-sm block w-full sm:text-sm rounded-md ${
                                          formikProps.touched.name_ar &&
                                          formikProps.errors.name_ar
                                            ? "focus:ring-red-500 focus:border-red-500 border-red-500"
                                            : "focus:ring-indigo-500 focus:border-indigo-500 border-gray-300"
                                        }`}
                                        disabled={submittingAdd}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-span-6 sm:col-span-3">
                                    <label
                                      htmlFor="name_en"
                                      className="block text-sm font-medium text-gray-700"
                                    >
                                      Name (english)
                                    </label>
                                    <div className="mt-1">
                                      <Field
                                        type="text"
                                        name="name_en"
                                        id="name_en"
                                        className={`shadow-sm block w-full sm:text-sm rounded-md ${
                                          formikProps.touched.name_en &&
                                          formikProps.errors.name_en
                                            ? "focus:ring-red-500 focus:border-red-500 border-red-500"
                                            : "focus:ring-indigo-500 focus:border-indigo-500 border-gray-300"
                                        }`}
                                        disabled={submittingAdd}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-span-6 sm:col-span-3">
                                    <label
                                      htmlFor="description_ar"
                                      className="block text-sm font-medium text-gray-700"
                                    >
                                      Description (arabic)
                                    </label>
                                    <div className="mt-1">
                                      <Field
                                        as="textarea"
                                        id="description_ar"
                                        name="description_ar"
                                        rows={3}
                                        className={`shadow-sm block w-full sm:text-sm border rounded-md ${
                                          formikProps.touched.description_ar &&
                                          formikProps.errors.description_ar
                                            ? "focus:ring-red-500 focus:border-red-500 border-red-500"
                                            : "focus:ring-indigo-500 focus:border-indigo-500 border-gray-300"
                                        }`}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-span-6 sm:col-span-3">
                                    <label
                                      htmlFor="description_en"
                                      className="block text-sm font-medium text-gray-700"
                                    >
                                      Description (english)
                                    </label>
                                    <div className="mt-1">
                                      <Field
                                        as="textarea"
                                        id="description_en"
                                        name="description_en"
                                        rows={3}
                                        className={`shadow-sm block w-full sm:text-sm border rounded-md ${
                                          formikProps.touched.description_en &&
                                          formikProps.errors.description_en
                                            ? "focus:ring-red-500 focus:border-red-500 border-red-500"
                                            : "focus:ring-indigo-500 focus:border-indigo-500 border-gray-300"
                                        }`}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-span-6 sm:col-span-3">
                                    <label
                                      htmlFor="category_id"
                                      className="block text-sm font-medium text-gray-700"
                                    >
                                      Category
                                    </label>
                                    <div className="mt-1">
                                      <Field
                                        as="select"
                                        id="category_id"
                                        name="category_id"
                                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                      >
                                        {categories.map((category) => (
                                          <option
                                            key={category.id}
                                            value={category.id}
                                          >
                                            {category.name_en}
                                          </option>
                                        ))}
                                      </Field>
                                    </div>
                                  </div>

                                  <div className="col-span-6 sm:col-span-3">
                                    <label
                                      htmlFor="url"
                                      className="block text-sm font-medium text-gray-700"
                                    >
                                      URL
                                    </label>
                                    <div className="mt-1">
                                      <Field
                                        type="text"
                                        name="url"
                                        id="url"
                                        className={`shadow-sm block w-full sm:text-sm rounded-md ${
                                          formikProps.touched.url &&
                                          formikProps.errors.url
                                            ? "focus:ring-red-500 focus:border-red-500 border-red-500"
                                            : "focus:ring-indigo-500 focus:border-indigo-500 border-gray-300"
                                        }`}
                                        disabled={submittingAdd}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-span-6 sm:col-span-3">
                                    {/* <div className="form-group">
                                      <input
                                        name="thumbnail"
                                        type="file"
                                        onChange={(event) => {
                                          formikProps.setTouched({
                                            ...formikProps.touched,
                                            thumbnail: true,
                                          });
                                          formikProps.setFieldValue(
                                            "thumbnail",
                                            event.target.files[0]
                                          );
                                        }}
                                        className={
                                          formikProps.touched.thumbnail
                                            ? formikProps.errors.thumbnail
                                              ? "form-control input_user is-invalid"
                                              : "form-control input_user is-valid"
                                            : "form-control"
                                        }
                                      ></input>
                                      {formikProps.touched.thumbnail &&
                                      formikProps.errors.thumbnail ? (
                                        <small className="text-danger">
                                          {formikProps.errors.thumbnail}
                                        </small>
                                      ) : null}
                                    </div>
                                    {formikProps.values.thumbnail ? (
                                      <PreviewImage
                                        file={formikProps.values.thumbnail}
                                      />
                                    ) : null} */}

                                    <label
                                      htmlFor="thumbnail"
                                      className="block text-sm font-medium text-gray-700"
                                    >
                                      Thumbnail
                                    </label>
                                    <div
                                      className={`mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-dashed rounded-md ${
                                        formikProps.touched.thumbnail &&
                                        formikProps.errors.thumbnail
                                          ? "border-red-500"
                                          : "border-gray-300"
                                      }`}
                                    >
                                      <div className="space-y-1 text-center">
                                        {formikProps.values.thumbnail ? (
                                          <PreviewImage
                                            file={formikProps.values.thumbnail}
                                          />
                                        ) : (
                                          <svg
                                            className="mx-auto h-12 w-12 text-gray-400"
                                            stroke="currentColor"
                                            fill="none"
                                            viewBox="0 0 48 48"
                                            aria-hidden="true"
                                          >
                                            <path
                                              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                              strokeWidth={2}
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            />
                                          </svg>
                                        )}

                                        <div className="flex text-sm text-gray-600">
                                          <label
                                            htmlFor="thumbnail"
                                            className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                          >
                                            <span>Upload a file</span>
                                            <input
                                              id="thumbnail"
                                              name="thumbnail"
                                              type="file"
                                              className="sr-only"
                                              onChange={(event) => {
                                                formikProps.setTouched({
                                                  ...formikProps.touched,
                                                  thumbnail: true,
                                                });
                                                formikProps.setFieldValue(
                                                  "thumbnail",
                                                  event.target.files[0]
                                                );
                                              }}
                                            />
                                          </label>
                                          <p className="pl-1">
                                            to use as a game thumbnail
                                          </p>
                                        </div>
                                        <p className="text-xs text-gray-500">
                                          PNG, JPG, GIF - preferred 600 X 300
                                          pixel
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-span-6 sm:col-span-3">
                                    <label
                                      htmlFor="image"
                                      className="block text-sm font-medium text-gray-700"
                                    >
                                      Image
                                    </label>
                                    <div
                                      className={`mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-dashed rounded-md ${
                                        formikProps.touched.image &&
                                        formikProps.errors.image
                                          ? "border-red-500"
                                          : "border-gray-300"
                                      }`}
                                    >
                                      <div className="space-y-1 text-center">
                                        {formikProps.values.image ? (
                                          <PreviewImage
                                            file={formikProps.values.image}
                                          />
                                        ) : (
                                          <svg
                                            className="mx-auto h-12 w-12 text-gray-400"
                                            stroke="currentColor"
                                            fill="none"
                                            viewBox="0 0 48 48"
                                            aria-hidden="true"
                                          >
                                            <path
                                              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                              strokeWidth={2}
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            />
                                          </svg>
                                        )}

                                        <div className="flex text-sm text-gray-600">
                                          <label
                                            htmlFor="image"
                                            className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                          >
                                            <span>Upload a file</span>
                                            <input
                                              id="image"
                                              name="image"
                                              type="file"
                                              className="sr-only"
                                              onChange={(event) => {
                                                formikProps.setTouched({
                                                  ...formikProps.touched,
                                                  image: true,
                                                });
                                                formikProps.setFieldValue(
                                                  "image",
                                                  event.target.files[0]
                                                );
                                              }}
                                            />
                                          </label>
                                          <p className="pl-1">
                                            to use as a game image
                                          </p>
                                        </div>
                                        <p className="text-xs text-gray-500">
                                          PNG, JPG, GIF - preferred +900 X +600
                                          pixel
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                          <button
                            type="submit"
                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-75"
                            disabled={submittingAdd}
                          >
                            {submittingAdd ? <LoadingButtonIcon /> : "Add"}
                          </button>
                          <button
                            type="button"
                            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-75"
                            disabled={submittingAdd}
                            onClick={() => setAddModalOpen(false)}
                            ref={cancelAddButtonRef}
                          >
                            Cancel
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                )}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <div>
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <Navbar sidebarOpen={sidebarOpen}>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <div className="flex flex-row justify-between">
              <h1 className="text-2xl font-semibold text-gray-900">Games</h1>
              <button
                onClick={() => {
                  getCategories();
                  setAddModalOpen(true);
                }}
                type="button"
                className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <PlusIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
                New Game
              </button>
            </div>
          </div>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            {/* Replace with your content */}
            <div className="py-4">
              <div className="bg-white shadow overflow-hidden sm:rounded-md">
                {loading ? (
                  <LoadingIcon />
                ) : games.length === 0 ? (
                  <div className="text-center m-12">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="mx-auto h-12 w-12 text-gray-400"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M11 4a2 2 0 114 0v1a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-1a2 2 0 100 4h1a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-1a2 2 0 10-4 0v1a1 1 0 01-1 1H7a1 1 0 01-1-1v-3a1 1 0 00-1-1H4a2 2 0 110-4h1a1 1 0 001-1V7a1 1 0 011-1h3a1 1 0 001-1V4z"
                      />
                    </svg>
                    <h3 className="mt-2 text-sm font-medium text-gray-900">
                      No Games
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      Get started and add some games to Shilail.
                    </p>
                    <div className="mt-6">
                      <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        <PlusIcon
                          className="-ml-1 mr-2 h-5 w-5"
                          aria-hidden="true"
                        />
                        New Game
                      </button>
                    </div>
                  </div>
                ) : (
                  <ul className="divide-y divide-gray-200">
                    {games.map((game) => (
                      <li key={game.id}>
                        <Link
                          to={"/game/" + game.id}
                          className="block hover:bg-gray-50"
                        >
                          <div className="px-4 py-4 sm:px-6">
                            <div className="flex items-center justify-between">
                              <p className="text-sm font-medium text-indigo-600 truncate">
                                {game.name_ar}
                              </p>
                              <div className="ml-2 flex-shrink-0 flex">
                                {game.published ? (
                                  <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                    Enabled
                                  </p>
                                ) : (
                                  <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-700">
                                    Disabled
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="mt-1 sm:flex sm:justify-between">
                              <div className="sm:flex">
                                <p className="flex items-center text-xs text-gray-500">
                                  <FolderIcon
                                    className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400"
                                    aria-hidden="true"
                                  />
                                  {game.category.name_en}
                                </p>
                              </div>
                              <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                                <UserGroupIcon
                                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                                <p>{game.players} Players</p>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
            {/* /End replace */}
          </div>
        </Navbar>
      </div>
    </>
  );
}

const PreviewImage = ({ file }) => {
  const [preview, setPreview] = React.useState(null);

  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    setPreview(reader.result);
  };

  return (
    <div className="text-center">
      <img src={preview} alt="Preview" className="mx-auto h-12 w-24" />
    </div>
  );
};
