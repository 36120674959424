import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import LoadingButtonIcon from "../LoadingButtonIcon";
import * as gameServices from "./../../services/game.service";
import { FolderOpenIcon } from "@heroicons/react/outline";

export default function GameUrlEdit({ game }) {
  const [edit, setEdit] = useState(false);
  const [submittingEdit, setSubmittingEdit] = useState(false);
  const [localGame, setLocalGame] = useState(game);

  const EditSchema = Yup.object().shape({
    url: Yup.string()
      .min(3, "Game url must be 3 characters at minimum")
      .required("Game url is required"),
  });

  const handleEdit = (url) => {
    setSubmittingEdit(true);
    let requestGame = game;
    requestGame.url = url;
    gameServices.edit(localGame.id, requestGame).then((response) => {
      setSubmittingEdit(false);
      if (response.status === 200) {
        setLocalGame(response.data.data);
        setEdit(false);
      } else {
        // TODO: handle error
      }
    });
  };

  return (
    <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
      <dt className="text-sm font-medium text-gray-500">Game URL</dt>
      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        <ul className="border border-gray-200 rounded-md divide-y divide-gray-200 bg-white">
          <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
            <div className="w-0 flex-1 flex items-center">
              <FolderOpenIcon
                className="flex-shrink-0 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              {edit ? (
                <Formik
                  initialValues={{
                    url: localGame.url,
                  }}
                  validationSchema={EditSchema}
                  onSubmit={(values) => handleEdit(values.url)}
                >
                  {({ touched, errors }) => (
                    <Form>
                      <div className="ml-2 flex flex-row items-center">
                        <Field
                          type="text"
                          name="url"
                          id="url"
                          className={`shadow-sm block sm:text-sm rounded-md ${
                            touched.url && errors.url
                              ? "focus:ring-red-500 focus:border-red-500 border-red-500"
                              : "focus:ring-indigo-500 focus:border-indigo-500 border-gray-300"
                          }`}
                          disabled={submittingEdit}
                        />
                        <button
                          type="submit"
                          className="ml-2 px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-75"
                          disabled={submittingEdit}
                        >
                          {submittingEdit ? (
                            <LoadingButtonIcon color="GRAY" />
                          ) : (
                            "Save Changes"
                          )}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              ) : (
                <span className="ml-2 flex-1 w-0 truncate">{game.url}</span>
              )}
            </div>
            <div className="ml-4 flex-shrink-0">
              <button
                onClick={() => setEdit(!edit)}
                type="button"
                className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {edit ? "Close" : "Change"}
              </button>
            </div>
          </li>
        </ul>
      </dd>
    </div>
  );
}
