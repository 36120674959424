import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import * as actions from "../../redux/actions/authActions";
import { LockClosedIcon } from "@heroicons/react/solid";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationIcon } from "@heroicons/react/outline";
import { useNavigate } from "react-router-dom";
import { toAbsoluteUrl } from "../../utils/common";

function Login() {
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [submittingLogin, setSubmittingLogin] = useState(false);

  const LoginSchema = Yup.object().shape({
    phoneNumber: Yup.string()
      .matches(/^((\d{9}))$/, "Phone number is not valid")
      .required("Phone number is required"),
    password: Yup.string()
      .min(4, "Password must be 4 characters at minimum")
      .required("Password is required"),
  });

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const invalidCredentials = useSelector((state) => state.auth.error);
  const authenticated = useSelector((state) => state.auth.authenticated);

  const handleSubmit = (phoneNumber, password) => {
    setSubmittingLogin(true);
    dispatch(actions.login(phoneNumber, password));
  };

  useEffect(() => {
    if (invalidCredentials === "INVALID_CREDENTIALS") {
      setErrorModalOpen(true);
      setSubmittingLogin(false);
    }
  }, [invalidCredentials]);

  useEffect(() => {
    if (authenticated) {
      navigate("/");
    }
  }, [authenticated, navigate]);

  const clearStateErrors = () => {
    dispatch(actions.clearState());
  };

  return (
    <>
      <Transition.Root show={errorModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={() => {
            setErrorModalOpen(false);
            clearStateErrors();
          }}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                <div>
                  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                    <ExclamationIcon
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Invalid Credentials
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Either the email or password provided are wrong.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => {
                      setErrorModalOpen(false);
                      clearStateErrors();
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <div className="h-screen flex justify-center items-center">
        <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
          <div className="max-w-md w-full space-y-8">
            <div>
              <img
                className="mx-auto h-20 w-auto"
                src={toAbsoluteUrl("/media/logo.png")}
                alt="Shilail"
              />
              <p className="mt-4 text-center text-sm text-gray-600">
                This is a private system. Unauthorized or illegal access to the
                system may
              </p>
            </div>
            <Formik
              initialValues={{ phoneNumber: "", password: "" }}
              validationSchema={LoginSchema}
              onSubmit={(values) =>
                handleSubmit(values.phoneNumber, values.password)
              }
            >
              {({ touched, errors, isSubmitting }) => (
                <Form className="mt-8 space-y-6">
                  <div className="rounded-md shadow-sm -space-y-px">
                    <div>
                      <label htmlFor="phone-number" className="sr-only">
                        Phone number
                      </label>
                      <div className="mt-1 relative rounded-t-md shadow-sm">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <span className="text-gray-500 sm:text-sm">+966</span>
                        </div>
                        <Field
                          type="text"
                          name="phoneNumber"
                          id="phone-number"
                          className={`block w-full pl-12 sm:pl-12 sm:text-sm rounded-t-md ${
                            touched.phoneNumber && errors.phoneNumber
                              ? "border-red-500 focus:ring-red-500 focus:border-red-500"
                              : "border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                          }`}
                        />
                      </div>
                    </div>
                    <div>
                      <label htmlFor="password" className="sr-only">
                        Password
                      </label>
                      <Field
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        required
                        className={`appearance-none rounded-none relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 rounded-b-md focus:outline-none focus:z-10 sm:text-sm ${
                          touched.password && errors.password
                            ? "border-2 border-red-700 focus:ring-red-700 focus:border-red-700"
                            : "border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                        }`}
                        placeholder="Password"
                      />
                    </div>
                  </div>

                  <div>
                    {!submittingLogin ? (
                      <button
                        type="submit"
                        className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                          <LockClosedIcon
                            className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                            aria-hidden="true"
                          />
                        </span>
                        Sign in
                      </button>
                    ) : (
                      <button
                        type="submit"
                        disabled
                        className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 opacity-50"
                      >
                        <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                          <LockClosedIcon
                            className="h-5 w-5 text-indigo-500"
                            aria-hidden="true"
                          />
                        </span>
                        Sign in
                      </button>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
