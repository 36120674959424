import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import LoadingButtonIcon from "../LoadingButtonIcon";
import * as gameServices from "./../../services/game.service";

export default function GameNameEdit({ game }) {
  const [edit, setEdit] = useState(false);
  const [submittingEdit, setSubmittingEdit] = useState(false);
  const [localGame, setLocalGame] = useState(game);

  const EditSchema = Yup.object().shape({
    name_ar: Yup.string()
      .min(3, "Game name (arabic) must be 3 characters at minimum")
      .required("Game name (arabic) is required"),
    name_en: Yup.string()
      .min(3, "Game name (english) must be 3 characters at minimum")
      .required("Game name (english) is required"),
  });

  const handleEdit = (name_en, name_ar) => {
    setSubmittingEdit(true);
    let requestGame = game;
    requestGame.name_ar = name_ar;
    requestGame.name_en = name_en;
    gameServices.edit(localGame.id, requestGame).then((response) => {
      setSubmittingEdit(false);
      if (response.status === 200) {
        setLocalGame(response.data.data);
        setEdit(false);
      } else {
        // TODO: handle error
      }
    });
  };

  return (
    <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
      <dt className="text-sm font-medium text-gray-500">Name</dt>
      <dd className="mt-1 flex items-center text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        <span className="flex-grow">
          {edit ? (
            <Formik
              initialValues={{
                name_ar: localGame.name_ar,
                name_en: localGame.name_en,
              }}
              validationSchema={EditSchema}
              onSubmit={(values) => handleEdit(values.name_en, values.name_ar)}
            >
              {({ touched, errors }) => (
                <Form>
                  <div className="flex flex-row items-center">
                    <Field
                      type="text"
                      name="name_en"
                      id="name_en"
                      className={`shadow-sm block sm:text-sm rounded-md ${
                        touched.name_en && errors.name_en
                          ? "focus:ring-red-500 focus:border-red-500 border-red-500"
                          : "focus:ring-indigo-500 focus:border-indigo-500 border-gray-300"
                      }`}
                      disabled={submittingEdit}
                    />
                    <Field
                      type="text"
                      name="name_ar"
                      id="name_ar"
                      className={`ml-2 shadow-sm block sm:text-sm rounded-md ${
                        touched.name_ar && errors.name_ar
                          ? "focus:ring-red-500 focus:border-red-500 border-red-500"
                          : "focus:ring-indigo-500 focus:border-indigo-500 border-gray-300"
                      }`}
                      disabled={submittingEdit}
                    />
                    <button
                      type="submit"
                      className="ml-2 px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-75"
                      disabled={submittingEdit}
                    >
                      {submittingEdit ? (
                        <LoadingButtonIcon color="GRAY" />
                      ) : (
                        "Save Changes"
                      )}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          ) : (
            localGame.name_en + " - " + localGame.name_ar
          )}
        </span>
        <span className="ml-4 flex-shrink-0">
          <button
            onClick={() => setEdit(!edit)}
            type="button"
            className="mr-4 rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {edit ? "Close" : "Update"}
          </button>
        </span>
      </dd>
    </div>
  );
}
