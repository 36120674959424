import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import LoadingButtonIcon from "../LoadingButtonIcon";
import * as gameServices from "./../../services/game.service";
import {
  MAX_IMAGE_FILE_SIZE,
  SUPPORTED_IMAGE_FORMATS,
} from "../../utils/common";
import PreviewGameUpload from "./PreviewGameUpload";

export default function GameThumbnailEdit({ game }) {
  const [edit, setEdit] = useState(false);
  const [submittingEdit, setSubmittingEdit] = useState(false);
  const [localGame, setLocalGame] = useState(game);

  const apiUrl = process.env.REACT_APP_API_URL;

  const EditSchema = Yup.object().shape({
    thumbnail: Yup.mixed()
      .test("fileRequired", "Game thumbnail is required", (value) => value)
      .test(
        "fileSize",
        "File is too large",
        (value) => value && value.size <= MAX_IMAGE_FILE_SIZE
      )
      .test(
        "fileType",
        "Your Error Message",
        (value) =>
          !value || (value && SUPPORTED_IMAGE_FORMATS.includes(value?.type))
      ),
  });

  const handleEdit = (thumbnail) => {
    setSubmittingEdit(true);
    let requestGame = game;
    requestGame.thumbnail = thumbnail;
    gameServices
      .edit(localGame.id, requestGame, { image: false, thumbnail: true })
      .then((response) => {
        setSubmittingEdit(false);
        if (response.status === 200) {
          setLocalGame(response.data.data);
          setEdit(false);
        } else {
          // TODO: handle error
        }
      });
  };

  return (
    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
      <dt className="text-sm font-medium text-gray-500">Thumbnail</dt>
      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        <ul className="border border-gray-200 rounded-md divide-y divide-gray-200 bg-white">
          <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
            <div className="w-0 flex-1 flex items-center">
              {edit ? (
                <Formik
                  initialValues={{
                    thumbnail: null,
                  }}
                  validationSchema={EditSchema}
                  onSubmit={(values) => handleEdit(values.thumbnail)}
                >
                  {(formikProps) => (
                    <Form>
                      <div className="ml-2 flex flex-row items-center">
                        <div className="mt-1 flex items-center space-x-5">
                          {formikProps.values.thumbnail ? (
                            <PreviewGameUpload
                              file={formikProps.values.thumbnail}
                              classNames="inline-block h-12 w-16 rounded-md overflow-hidden"
                              error={
                                formikProps.touched.thumbnail &&
                                formikProps.errors.thumbnail
                              }
                            />
                          ) : (
                            <img
                              className="inline-block h-12 w-16 rounded-md overflow-hidden"
                              src={
                                apiUrl +
                                "/resources/images/games/" +
                                localGame.thumbnail
                              }
                              alt={localGame.name_en}
                            />
                          )}
                          <label
                            htmlFor="thumbnail"
                            className="relative bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          >
                            <span>Browse</span>
                            <input
                              id="thumbnail"
                              name="thumbnail"
                              type="file"
                              className="absolute inset-0 w-16 opacity-0 cursor-pointer border-gray-300 rounded-md"
                              onChange={(event) => {
                                formikProps.setTouched({
                                  ...formikProps.touched,
                                  thumbnail: true,
                                });
                                formikProps.setFieldValue(
                                  "thumbnail",
                                  event.target.files[0]
                                );
                              }}
                              disabled={submittingEdit}
                            />
                          </label>
                          {formikProps.values.thumbnail && (
                            <>
                              <button
                                type="button"
                                className="bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-red-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                onClick={() => {
                                  formikProps.setFieldValue("thumbnail", null);
                                }}
                                disabled={submittingEdit}
                              >
                                Remove
                              </button>
                              <button
                                type="submit"
                                className="bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                disabled={submittingEdit}
                              >
                                {submittingEdit ? (
                                  <LoadingButtonIcon color="GRAY" />
                                ) : (
                                  "Save Changes"
                                )}
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              ) : (
                <span className="ml-2 mt-1 flex-1 w-0 truncate">
                  <img
                    className="inline-block h-12 w-16 rounded-md overflow-hidden"
                    src={
                      apiUrl + "/resources/images/games/" + localGame.thumbnail
                    }
                    alt={localGame.name_en}
                  />
                </span>
              )}
            </div>
            <div className="ml-4 flex-shrink-0">
              <button
                onClick={() => setEdit(!edit)}
                type="button"
                className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                disabled={submittingEdit}
              >
                {edit ? "Close" : "Change"}
              </button>
            </div>
          </li>
        </ul>
      </dd>
    </div>
  );
}
